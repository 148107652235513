import React, { useState } from "react";

// React Bootstrap
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import LoginHelpModalData from "./login-help-modal-data";
import ModalGeneric from "../custom-widgets/modal-generic";

// Styles
import "./login.bootstrap.scss";

const CommercialCreditCardLogin = (props) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (e) => {
    e.preventDefault();
    setShow(true);
  };

  const loginHelpModalData = {
    handleClose,
    show,
    modalSize: "md",
    header: {
      id: "cc-sign-in-help-modal-title",
      class: "mb-0",
      text: "Sign In Help"
    },
    body: {
      class: "",
      content: [<LoginHelpModalData />]
    }
  };
  const [key, setKey] = useState("Cardholder");

  return (
    <div id="commercial-credit-login-box" className={`dropdown-login-box bg-white shadow ${props.className}`}>
      <Tabs activeKey={key} onSelect={(k) => setKey(k)} aria-expanded="true">
        <Tab
          id="company-cardholder-login-nav-tab"
          eventKey="Cardholder"
          title="Cardholder"
          className="text-secondary font-weight-bold"
        >
          {/* Panel for logging into personal banking */}
          <div id="company-cardholder-login-tab-content" className="personal-login-panel">
            <div className="p-3">
              <p className="lead">Sign In to {props.loginType} Credit Cards (eZCard)</p>
              <a
                id="sign-in-btn"
                className="btn btn-success btn-block sign-on-btn mb-4 text-center"
                href="https://www.ezcardinfo.com/?WAFD=/"
              >
                Sign In
              </a>
            </div>
            <div className="row py-2 m-0 w-100 border-top list-unstyled text-center bg-light">
              <div className="col-sm-9 text-center mb-2 mb-sm-0 border-sm-right">
                <a
                  className="font-weight-bold text-decoration-none text-underline"
                  href="https://www.ezcardinfo.com/?WAFD=/"
                  id="sign-up-link"
                >
                  Sign Up for Online Access
                </a>
              </div>

              <div className="col-sm-3 text-center">
                <a
                  href="/#"
                  id="commercial-cc-login-help"
                  className="text-decoration-none text-underline"
                  onClick={handleShow}
                >
                  Help
                </a>
              </div>
              {show && <ModalGeneric {...loginHelpModalData} />}
            </div>
          </div>
        </Tab>

        <Tab
          id="administrator-login-nav-tab"
          eventKey="Administrator"
          title="Administrator"
          className="text-secondary font-weight-bold"
        >
          <div id="administrator-login-tab-content">
            <div className="p-3">
              <p className="lead">Sign In to {props.loginType} Credit Cards (eZBusiness)</p>
              <a
                id="administrator-sign-in-btn"
                className="btn btn-success btn-block mb-4 text-center"
                href="https://www.ezbusinesscardmanagement.com/bid/88003S"
                role="button"
              >
                Sign In
              </a>
            </div>
            <div className="row py-2 m-0 w-100 border-top list-unstyled text-center bg-light">
              <div className="col-sm-9 text-center mb-2 mb-sm-0 border-sm-right">
                <a className="font-weight-bold" href="https://www.ezcardinfo.com/?WAFD=/" id="sign-up-admin-link">
                  Sign Up for Online Access
                </a>
              </div>

              <div className="col-sm-3 text-center">
                <a href="/#" id="commercial-cc-admin-login-help" onClick={handleShow}>
                  Help
                </a>
              </div>
              {show && <ModalGeneric {...loginHelpModalData} />}
            </div>
          </div>
        </Tab>
      </Tabs>
    </div>
  );
};

export default CommercialCreditCardLogin;

CommercialCreditCardLogin.defaultProps = {
  loginType: "Commercial", // "Commercial" or "Business"
  className: "float-right d-none d-md-block" // Default class used when login is on hero
};

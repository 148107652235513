import React from "react";
import { useLanguageContext } from "../../contexts/language-context";

// Components
import Modal from "react-bootstrap/Modal";

const OnlineEnrollmentModal = (props) => {
  const isSpanish = useLanguageContext();

  return (
    <Modal show={props.show} onHide={props.handleClose} size="md">
      <Modal.Header closeButton>
        <Modal.Title id="online-enrollment-modal-header" className="mb-0">
          {isSpanish ? "Clientes nuevos inscribirse" : "Online Enrollment"}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {isSpanish ? (
          <>
            <h5>Soy un usuario de banca en línea existente</h5>
            <p>
              Si se ha inscrito anteriormente en WaFd Online Banking, utilice su nombre de usuario y contraseña para
              iniciar sesión.
            </p>
            <div className="col-mb-4">
              {" "}
              <a
                href="https://online.wafdbank.com"
                className="btn btn-success olb-sign-in-link"
                id="enroll-modal-es-olb-sign-in-link"
              >
                Iniciar sesión
              </a>
            </div>
            <h5>Soy un nuevo cliente de WaFd Bank</h5>
            <p>Los nuevos clientes pueden inscribirse en la banca en línea.</p>
            <a
              href="https://online.wafdbank.com/link/register?application=OLB"
              className="btn btn-primary olb-enroll-link"
              id="enroll-modal-es-olb-enroll-link"
            >
              Inscribirse
            </a>
          </>
        ) : (
          <>
            <h5>I'm an existing online banking user</h5>
            <p>If you've previously enrolled in WaFd Online Banking, use your username and password to Sign In.</p>
            <div className="mb-4">
              <a
                href="https://online.wafdbank.com"
                className="btn btn-success olb-sign-in-link"
                id="enroll-modal-olb-sign-in-link"
              >
                Sign In
              </a>
            </div>
            <h5>I'm a new WaFd Bank Customer</h5>
            <p>Brand new customers can enroll in online banking.</p>
            <a
              href="https://online.wafdbank.com/link/register?application=OLB"
              className="btn btn-primary olb-enroll-link"
              id="enroll-modal-olb-enroll-link"
            >
              Enroll
            </a>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default OnlineEnrollmentModal;

OnlineEnrollmentModal.defaultProps = {
  id: 1,
  handleClose: null, // close handler callback
  show: false // open handler callback
};

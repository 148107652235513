const checkMktoFormIsSubmitted = () => {
  if (typeof window === "undefined") {
    return false;
  }
  // if alild is present in Url that means form is submitted

  let match;
  let urlParams = {};
  const replaceAddition = /\+/g; // Regex for replacing addition symbol with a space
  const search = /([^&=]+)=?([^&]*)/g;
  const decode = (s) => {
    return decodeURIComponent(s.replace(replaceAddition, " "));
  };
  const query = window.location.search.substring(1);

  while ((match = search.exec(query))) {
    urlParams[decode(match[1])] = decode(match[2]);
  }
  return "aliId" in urlParams;
};

export default checkMktoFormIsSubmitted;

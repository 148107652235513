import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

// Components
import SecondaryLanding from "../../../layouts/secondary-landing";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import SEO from "../../../components/seo/seo";
import List from "../../../components/custom-widgets/list";
import HeroChevron from "../../../components/hero/hero-chevron";
import CommercialCreditCardLogin from "../../../components/login/commercial-credit-card-login";
import MktoForm from "../../../components/mkto-form/mkto-form";
import Icon from "../../../components/custom-widgets/icon";

//helper functions
import showMktoForm from "../../../helpers/showMktoForm";
import checkMktoFormIsSubmitted from "../../../helpers/checkMktoFormIsSubmitted";

import getHeroImgVariables from "../../../helpers/getHeroImgVariables";
import VimeoVideoFrame from "../../../components/custom-widgets/vimeo-video-frame";

const CommercialCreditCard = () => {
  const title = "Commercial Preferred Solution Credit Card";
  const description =
    "Discover the WaFd Bank Visa® commercial credit card. The company credit card designed to reward business spending and optimize working capital.";
  const imgData = useStaticQuery(graphql`
    query {
      imgVariableXXL: file(
        relativePath: { eq: "hero/commercial-banking/credit-card/hero-commercial-credit-card-011023-XXL.jpg" }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: { eq: "hero/commercial-banking/credit-card/hero-commercial-credit-card-011023-XL.jpg" }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: { eq: "hero/commercial-banking/credit-card/hero-commercial-credit-card-011023-LG.jpg" }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: { eq: "hero/commercial-banking/credit-card/hero-commercial-credit-card-011023-MD.jpg" }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: { eq: "hero/commercial-banking/credit-card/hero-commercial-credit-card-011023-SM.jpg" }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: { eq: "hero/commercial-banking/credit-card/hero-commercial-credit-card-011023-XS.jpg" }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: { eq: "hero/commercial-banking/credit-card/hero-commercial-credit-card-011023-XXS.jpg" }
      ) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);

  const SEOData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/commercial-banking/commercial-credit-card"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-commercial-credit-card-011023.jpg"
      }
    ]
  };

  const heroChevronData = {
    id: "commercial-credit-card-hero",
    chevronBgClass: "bg-info",
    ...getHeroImgVariables(imgData),
    altText: "Businesswoman holding WaFd Bank Commercial credit card making a mobile purchase.",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Looking for more cashflow? Ask about CollectEarly<sup>&reg;</sup>"
          }
        },
        {
          id: "hero-cta-btn-1",
          button: {
            type: "button",
            text: "Contact Us",
            onClick: () => showMktoForm(1067),
            class: "btn-white"
          }
        }
      ]
    }
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/commercial-banking",
      title: "Commercial Banking"
    },
    {
      id: 2,
      active: true,
      title: "Commercial Credit Cards"
    }
  ];

  const cashBackBenefitsListData = {
    greenCircleCheckmark: true,
    items: [
      {
        id: 1,
        text: "Online card management keeps everything in one place<br/><a target='_blank' id='ezcard-ref-guide' href='/documents/ezcard-quick-reference-guide.pdf'>eZCard Quick Reference Guide</a>"
      },
      {
        id: 2,
        text: "Virtual card options allow you more control to authorize company spending"
      },
      {
        id: 3,
        text: "Expense management to save you time and money"
      },
      {
        id: 4,
        text: "Rewards that you can actually use, including travel perks, gift cards, and statement credit options"
      }
    ]
  };

  const purchasingCardListData = {
    greenCircleCheckmark: true,
    items: [
      {
        id: 1,
        text: "Save time and money with our easy expense management tools"
      },
      {
        id: 2,
        text: "Earn competitive cash rebates based on your company's quarterly or annual spend"
      },
      {
        id: 3,
        text: "Co-branded card options can include your company logo"
      }
    ]
  };

  const cashBackDocumentsListData = {
    items: [
      {
        id: "commercial-preferred-solution-cc",
        text: `WaFd Bank Visa<sup>&reg;</sup> Commercial Preferred Solution Credit Card Rate`,
        url: "/documents/credit-cards/commercial/commercial-credit-card-cb-rates.pdf"
      },
      {
        id: "commercial-cardholder-agreement",
        text: "WaFd Bank Cardholder Agreement",
        url: "/documents/credit-cards/commercial/commercial-credit-card-cb-card-holder-agreement.pdf"
      },
      {
        id: "commercial-cc-guide-to-benefits",
        text: "WaFd Bank Visa<sup>&reg;</sup> Commercial Credit Card Guide to Benefits",
        url: "/documents/credit-cards/commercial/commercial-credit-card-guide-to-benefits-visa.pdf"
      },
      {
        id: "cash-advance-addendum-card-master-agreement",
        text: "Cash Advance Addendum to Visa<sup>&reg;</sup> Commercial Card Master Agreement",
        url: "/documents/credit-cards/commercial/commercial-credit-card-cb-cash-advance-addendum-card-master-agreement.pdf"
      },
      {
        id: "commercial-credit-card-cb-visa-master-agreement",
        text: "Visa<sup>&reg;</sup> Commercial Card Master Agreement",
        url: "/documents/credit-cards/commercial/commercial-credit-card-cb-visa-master-agreement.pdf"
      },
      {
        id: "faq-scorecard-rewards-program-for-vcps",
        text: "Frequently Asked Questions Regarding the ScoreCard Rewards Program for Visa Commercial Preferred Solution (VCPS)",
        url: "/documents/credit-cards/commercial/faq-scorecard-rewards-program-for-vcps.pdf"
      },
      {
        id: "scorecard-bonus-point-program-rules-for-vcps",
        text: "Scorecard<sup>&reg;</sup> Bonus Point Program Rules for Visa Commercial Preferred Solution (VCPS)",
        url: "/documents/credit-cards/commercial/scorecard-bonus-point-program-rules-for-vcps.pdf"
      }
    ]
  };

  const purchasingDocumentsListData = {
    items: [
      {
        id: "pc-commercial-credit-card-purchaser-rates",
        text: "WaFd Bank Visa<sup>&reg;</sup> Purchasing Card Rate",
        url: "/documents/credit-cards/commercial/commercial-credit-card-purchaser-rates.pdf"
      },
      {
        id: "pc-commercial-card-holder-agreement",
        text: "WaFd Bank Cardholder Agreement",
        url: "/documents/credit-cards/commercial/commercial-credit-card-purchasing-credit-card-card-holder-agreement.pdf"
      },
      {
        id: "pc-commercial-credit-card-guide-to-benefits",
        text: "WaFd Bank Visa<sup>&reg;</sup> Commercial Credit Card Guide to Benefits",
        url: "/documents/credit-cards/commercial/commercial-credit-card-guide-to-benefits-visa.pdf"
      },
      {
        id: "pc-cash-advance-addendum-card-master-agreement",
        text: "Cash Advance Addendum to Visa<sup>&reg;</sup> Commercial Card Master Agreement",
        url: "/documents/credit-cards/commercial/commercial-credit-card-purchasing-cash-advance-addendum-card-master-agreement.pdf"
      },
      {
        id: "pc-visa-master-agreement",
        text: "Visa<sup>&reg;</sup> Purchasing Card Master Agreement",
        url: "/documents/credit-cards/commercial/commercial-credit-card-purchasing-visa-master-agreement.pdf"
      }
    ]
  };

  const ePayablesSolutionListData = {
    greenCircleCheckmark: true,
    items: [
      {
        id: 1,
        text: "Streamline your Accounts Payables process for improved efficiency"
      },
      {
        id: 2,
        text: "Reduce your operational costs"
      },
      {
        id: 3,
        text: "Use virtual accounts to keep your information safe"
      },
      {
        id: 4,
        text: "Integrate easily into software and tools with customized reporting"
      }
    ]
  };

  const ePayablesSolutionDocumentsListData = {
    items: [
      {
        id: "vpa-interest-rate-and-fee-schedule",
        text: "WaFd Bank Visa<sup>&reg;</sup> Payables Automation Interest Rate and Fee Schedule",
        url: "/documents/credit-cards/commercial/wafdbank-vpa-interest-rate-and-fee-schedule.pdf"
      },
      {
        id: "vpa-agreement",
        text: "WaFd Bank Visa<sup>&reg;</sup> Payables Automation Agreement",
        url: "/documents/credit-cards/commercial/wafdbank-vpa-agreement.pdf"
      }
    ]
  };

  const videoData = {
    vimeoId: "878782350",
    videoTitle: "Visa Commercial Card: The Wait is Over | WaFd Bank",
    class: "iframe m-auto border-radius-12 w-100 border"
  };

  const isMktoFormSubmitted = checkMktoFormIsSubmitted();

  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container pb-0">
        <div className="row align-items-start d-flex">
          <div className="col-12 col-lg-7 col-xl-8">
            <h1>Commercial Credit Cards</h1>
            <h2 className="text-green-60">
              Flexible commercial credit options allow you to manage cashflows while earning incentives that reward your
              company spending.
            </h2>
          </div>
          <div className="col-md-6 col-lg-5 col-xl-4">
            <CommercialCreditCardLogin className="d-block" />
          </div>
        </div>
      </section>
      <section className="container border-bottom pb-0">
        <div className="row py-sm-1">
          <div className="col-md-4 mb-4">
            <StaticImage
              src="../../../images/wafd-cards/thumbnail-credit-commercial-vertical-062623.jpg"
              alt="WaFd Bank Commercial Credit Card"
              placeholder="blurred"
              className="d-none d-md-block "
              quality="100"
              imgStyle={{ objectFit: "contain" }}
            />

            <StaticImage
              src="../../../images/wafd-cards/thumbnail-credit-commercial-horizontal-062623.jpg"
              alt="WaFd Bank Commercial Credit Card"
              placeholder="blurred"
              quality="100"
              className="d-md-none mt-1 "
              width={238}
            />
          </div>

          <div className="col-md-8 pl-sm-4">
            <h2>
              WaFd Bank Visa<sup>&reg;</sup> Commercial Preferred Solution Credit Card
            </h2>
            <p>
              Designed to meet the needs of company owners' personal preferences while rewarding business spending. This
              solution rewards both travel and entertainment (T&amp;E) and purchasing of goods and services.
            </p>

            <List {...cashBackBenefitsListData} accountName="cash-back-cc-benefits" />
            <MktoForm />
            <div className="mb-4">
              {isMktoFormSubmitted ? (
                <>
                  <h3 className="font-weight-bold">Thank you! </h3>
                  <p>
                    Your information has successfully been submitted. A Commercial Banker in your area will connect with
                    you shortly.
                  </p>
                </>
              ) : (
                <h5>
                  <span className="text-success">Ready to get started?</span> Contact a{" "}
                  <a
                    id="contact-commercial-banker-button"
                    type="button"
                    className="anchor-link"
                    href="/#"
                    onClick={(e) => {
                      e.preventDefault();
                      showMktoForm(1067);
                    }}
                  >
                    Commercial Banker
                  </a>{" "}
                  today.
                </h5>
              )}
            </div>

            <VimeoVideoFrame {...videoData} />

            <div className="mt-3">
              <h3 className="text-success">The wait is over! Get your WaFd Visa Commercial Card.</h3>
              <ul className="list-unstyled text-sm">
                {cashBackDocumentsListData.items.map((document) => (
                  <li key={document.id}>
                    <a
                      href={document.url}
                      target="_blank"
                      id={document.id}
                      rel="noreferrer"
                      className={document.url.includes(".pdf") ? "text-decoration-none" : ""}
                    >
                      {document.url.includes(".pdf") && <Icon name="file-pdf" class="mr-2 mt-1" />}
                      <span dangerouslySetInnerHTML={{ __html: document.text }} />
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="container border-bottom pb-0">
        <div className="row">
          <div className="col-md-6">
            <h2>
              WaFd Bank Visa<sup>&reg;</sup> Purchasing Card
            </h2>
            <p>
              Spending to meet your business needs is easy with a WaFd Bank Visa<sup>&reg;</sup> Purchasing card. This
              charge card allows you to earn rewards based on your goods and services spend.
            </p>
            <List {...purchasingCardListData} accountName="purchasing-card-list" />
            <h5>
              Contact a{" "}
              <Link to="/commercial-banking/commercial-banker-near-me" id="commercial-banker-cta">
                Commercial Banker
              </Link>{" "}
              to learn more and get started today.
            </h5>
            <div className="mt-4">
              <ul className="list-unstyled text-sm">
                {purchasingDocumentsListData.items.map((document) => (
                  <li key={document.id}>
                    <a
                      href={document.url}
                      target="_blank"
                      id={document.id}
                      rel="noreferrer"
                      className={document.url.includes(".pdf") ? "text-decoration-none" : ""}
                    >
                      {document.url.includes(".pdf") && <Icon name="file-pdf" class="mr-2 mt-1" />}
                      <span dangerouslySetInnerHTML={{ __html: document.text }} />
                    </a>
                  </li>
                ))}
              </ul>
              <p className="text-sm text-secondary" id="visa-purchasing-card-disclaimer">
                Subject to credit approval. Certain terms, conditions, and exclusions apply. Please refer to your Visa
                Guide to Benefits and Cardholder Agreement for details.
              </p>
            </div>
          </div>
          <div className="col-md-6 pl-md-4 mb-3">
            <StaticImage
              src="../../../images/wafd-cards/thumbnail-commercial-visa-purchasing-card-062623.jpg"
              alt="WaFd Bank Visa<sup>&reg;</sup> Purchasing Card Co Branded card option"
              placeholder="blurred"
              className="mx-auto text-center d-none d-md-block"
              quality="100"
              imgStyle={{ objectFit: "contain" }}
            />
            <StaticImage
              src="../../../images/wafd-cards/thumbnail-commercial-visa-purchasing-card-062623.jpg"
              alt="WaFd Bank Commercial Credit Card"
              placeholder="blurred"
              quality="100"
              className="d-md-none"
              width={258}
            />
          </div>
        </div>
      </section>
      <section className="container">
        <div className="row">
          <div className="col-md-6 mb-3 mb-md-0">
            <StaticImage
              src="../../../images/thumbnail-commercial-credit-cards-epayables-091123.jpg"
              alt="Businessman smiling using a laptop. "
              placeholder="blurred"
              quality="100"
            />
          </div>
          <div className="col-md-6">
            <h2>WaFd Bank ePayables Solution</h2>
            <h4>
              Enhance your Purchasing program and earn rebates when you use our ePayables solution that allows for the
              secure electronic delivery of Visa payments to your suppliers.
            </h4>
            <List {...ePayablesSolutionListData} accountName="epayables-solution-list" />
            <h5>
              Contact a{" "}
              <Link to="/commercial-banking/commercial-banker-near-me" id="commercial-banker-cta-2">
                Commercial Banker
              </Link>{" "}
              to learn more and get started today.
            </h5>
            <div className="mt-4">
              <ul className="list-unstyled text-sm">
                {ePayablesSolutionDocumentsListData.items.map((document) => (
                  <li key={document.id}>
                    <a
                      href={document.url}
                      target="_blank"
                      id={document.id}
                      rel="noreferrer"
                      className={document.url.includes(".pdf") ? "text-decoration-none" : ""}
                    >
                      {document.url.includes(".pdf") && <Icon name="file-pdf" class="mr-2 mt-1" />}
                      <span dangerouslySetInnerHTML={{ __html: document.text }} />
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>
    </SecondaryLanding>
  );
};
export default CommercialCreditCard;

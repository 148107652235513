import React, { useState } from "react";

import OnlineEnrollModal from "../custom-widgets/online-enroll-modal";

const LoginHelpModalData = () => {
  const [showEnrollModal, setShowEnrollModal] = useState(false);
  const handleCloseEnrollModal = () => setShowEnrollModal(false);
  const handleShowEnrollModal = (e) => {
    e.preventDefault();
    setShowEnrollModal(true);
  };

  const onlineEnrollModal = {
    id: "online-enroll-modal",
    handleClose: handleCloseEnrollModal,
    show: showEnrollModal
  };

  return (
    <>
      {showEnrollModal && <OnlineEnrollModal {...onlineEnrollModal} />}
      <h5>Trouble Signing In?</h5>
      <p>
        Before signing into online banking, you must first have a signin username and password. If you don't have one,
        please{" "}
        <a href="/#" id="help-modal-sign-up" onClick={handleShowEnrollModal}>
          sign up
        </a>{" "}
        and enroll in online banking.
      </p>
      <h5>Forgot both your Username and Password?</h5>
      <p>
        Please call us at{" "}
        <a class="help-modal-c3-tel" href="tel:800-324-9375">
          800-324-9375
        </a>
        . For your protection we will have to authenticate a couple of things with you before resetting your access.
      </p>
      <h5>Locked Out?</h5>
      <p>
        After several access attempts your account may be locked out. In order to unlock your account please contact us
        at{" "}
        <a class="help-modal-c3-tel" href="tel:800-324-9375">
          800-324-9375
        </a>
        .
      </p>
      <h5>Still need help?</h5>
      <p>
        Our Client Care Center is open 24 hours a day 7 days a week, please contact us at{" "}
        <a class="help-modal-c3-tel" href="tel:800-324-9375">
          800-324-9375
        </a>
        .
      </p>
    </>
  );
};

export default LoginHelpModalData;
